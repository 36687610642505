<template>
  <AppPage title="Search Vet" hideAppBar>
    <div class="app-bar" v-if="locationAdded" @click="addLocation">
      <p v-if="currentLocationAddress">
        {{ currentLocationAddress.name | trim(30) }}
        <v-icon color="primary" small>mdi-chevron-down</v-icon>
      </p>

      <p v-else>
        {{ $vuetify.lang.t("$vuetify.current_location") }}
        <v-icon color="primary" small>mdi-chevron-down</v-icon>
      </p>
    </div>

    <div class="map-page" :class="`${isIOS ? 'isIOS' : ''}`">
      <div id="map" :key="reUpdate">
        <gmap-map :zoom="14" :center="center" :options="mapOptions">
          <gmap-marker
            :key="index"
            v-for="(m, index) in locationMarkers"
            :icon="m.icon"
            :position="m.position"
            @click="markerClickHandler(m)"
          ></gmap-marker>
        </gmap-map>
      </div>

      <template v-if="locationAdded">
        <v-btn-toggle rounded dense class="toggle-vet-view">
          <v-btn
            class="capitalize"
            color="#fff"
            to="/vet-list"
            :disabled="!vetList.length"
          >
            <v-icon class="mr-2" small>mdi-format-list-bulleted</v-icon>
            {{ $vuetify.lang.t("$vuetify.list") }}
          </v-btn>
          <v-btn class="capitalize" color="#fff" @click="openFilter">
            <v-img
              src="@/components/svg/icon/filter.svg"
              height="16"
              contain
              class="mr-2"
            ></v-img>
            {{ $vuetify.lang.t("$vuetify.filters") }}
          </v-btn>
        </v-btn-toggle>

        <!-- vet list -->
        <div class="vet-list over-flow w-100" :class="{ loading }">
          <swiper
            ref="mySwiper"
            :options="swiperOption"
            @slideChange="sliderChange"
            v-if="!loading"
          >
            <swiper-slide v-for="vet in vetList" :key="vet.id">
              <div class="vet-item">
                <VetCard :vet="vet" dense />
              </div>
            </swiper-slide>
          </swiper>

          <div class="vet-item" v-else>
            <v-skeleton-loader
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="pre-vet over-flow" @click="addLocation">
          <div class="d-flex align-items-center">
            <img src="@/assets/svg/paw-logo.svg" height="18px" contain left />
            <h3>{{ $vuetify.lang.t("$vuetify.find_vets_near_you") }}</h3>
          </div>

          <v-text-field
            outlined
            dense
            readonly
            hide-details
            disabled
            class="rounded-lg"
            :placeholder="$vuetify.lang.t('$vuetify.enter_your_address')"
          ></v-text-field>
        </div>
      </template>
    </div>
  </AppPage>
</template>

<script>
import VetCard from "@/components/cards/vet-card";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import { createNamespacedHelpers, mapState } from "vuex";
const vetModule = createNamespacedHelpers("vet");
const popupModule = createNamespacedHelpers("popup");
const userModule = createNamespacedHelpers("user");

import MixDevice from "@/mixin/mix-device.js";
export default {
  name: "search-vet",
  components: { VetCard, swiper, swiperSlide },
  mixins: [MixDevice],
  data() {
    return {
      swiperOption: {
        loop: false,
      },
    };
  },
  computed: {
    ...mapState(["isCordova"]),
    ...vetModule.mapState([
      "loading",
      "vetList",
      "center",
      "locationMarkers",
      "currentLocationAddress",
      "locationAdded",
      "reUpdate",
    ]),
    ...userModule.mapState(["user"]),
    mapOptions() {
      return {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        backgroundColor: "#f3f3f3",
        styles: [
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      };
    },
  },
  methods: {
    ...vetModule.mapActions(["FETCH_VET_LIST"]),
    ...vetModule.mapMutations([
      "SET_CENTER_POSITION",
      "PUSH_LOCATION_MARKER",
      "SET_LOCATION_ADDRESS",
      "SET_CURRENT_LOCATION",
      "SET_LOCATION_ADDED",
    ]),
    ...popupModule.mapMutations(["SET_SHEET"]),
    sliderChange() {
      const index = this.$refs.mySwiper.swiper.activeIndex;
      const { latitude, longitude } = this.vetList[index];
      if (latitude && longitude)
        this.SET_CENTER_POSITION({
          lat: Number(latitude),
          lng: Number(longitude),
        });
    },
    markerClickHandler(m) {
      if (!m.id) return;

      if (m.isSeller) {
        this.$router.push(`/seller-detail/${m.id}`);
      } else {
        this.$router.push(`/vet-detail/${m.id}`);
      }
    },
    openFilter() {
      this.SET_SHEET({
        showSheet: true,
        sheetComponent: "vet-filter",
      });
    },
    addLocation() {
      this.SET_SHEET({
        showSheet: true,
        sheetComponent: "vet-search-location",
        sheetOptions: {
          fullscreen: true,
        },
      });
    },
    getLocationFromMob() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          const position = {
            lat: latitude,
            lng: longitude,
          };
          setTimeout(() => {
            this.setUserPosition(position);
          }, 1000);
        },
        (err) => {
          window.showAlert(
            "We need your geo-coordinates to display nearby pets. Please enable the location  access in the settings.",
            "Location Access Failed!"
          );
          console.log(err);
        },
        { maximumAge: 30000, timeout: 50000, enableHighAccuracy: true }
      );
    },

    setUserPosition(position) {
      const { lat, lng } = position;
      const geocoder = new window.google.maps.Geocoder();
      const latlng = new window.google.maps.LatLng(lat, lng);

      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const country_code = this.extractCountryCode(
              results[0].address_components
            );
            this.SET_LOCATION_ADDRESS({
              name: results[0].formatted_address,
              formatted_address: results[0].formatted_address,
              position,
              country_code,
            });
          } else {
            window.showAlert("address not found", "Error");
          }
        } else {
          window.showAlert("Geocoder failed due to: " + status, "Error");
        }
      });

      this.PUSH_LOCATION_MARKER({
        position,
        icon: {
          url: require("@/assets/svg/marker.svg"),
        },
      });

      this.SET_CURRENT_LOCATION(position);
      this.SET_CENTER_POSITION(position);
      this.FETCH_VET_LIST();
      this.SET_LOCATION_ADDED(true);
    },
    extractCountryCode(address_components) {
      let country_code = this.user.country_code;
      address_components.forEach((add) => {
        const isCountry = add.types.includes("country");
        if (isCountry) {
          country_code = add.short_name;
        }
      });

      return country_code;
    },
  },
  mounted() {},
  created() {
    if (!this.vetList.length) {
      this.getLocationFromMob();
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;

  &::v-deep .vue-map-container {
    min-height: 100%;

    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
      position: fixed;
      bottom: unset !important;
      top: 50%;
    }
  }
}

.map-page {
  min-height: calc(100vh - 56px);
  position: relative;

  .vet-list {
    max-width: 100%;
    overflow-y: hidden;

    .vet-item {
      margin: 1rem;
      margin-right: 0;
      background: #fff;
      border-radius: 20px;
    }

    .swiper-container {
      padding-right: 3rem;

      .swiper-slide {
        width: 100% !important;
      }
    }

    &.loading {
      right: 0;
      left: 0;

      .vet-item {
        margin: 1rem;
      }
    }
  }

  .over-flow {
    position: absolute;
    bottom: 0;
  }

  .pre-vet {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;

    > div:first-child {
      margin-bottom: 5px;
    }

    h3 {
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .toggle-vet-view {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 122px;
  }
}

.app-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  text-align: center;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
  padding-top: 2rem;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
}

.map-page.isIOS {
  .over-flow {
    bottom: 20px;
  }
}
</style>
